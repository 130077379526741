import React from "react";
// import 'antd/dist/reset.css';
import 'antd/dist/antd.css';
import '../color.css';
import './ContactUs.css';
import '../public.css';
import TextArea from "antd/es/input/TextArea";
import {Button, Checkbox, Input, message} from "antd";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import axios from "axios";



function ContactUs () {
    const onChange = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`);
        (window as any).checked = e.target.checked;
    };

    const submitForm = () => {
        if((window as any).checked === true) {
            // @ts-ignore
            if (document.getElementsByClassName('input')[0].value === '') {
                message.error('請輸入聯絡人姓名!');
                return;
            }
            // @ts-ignore
            if (document.getElementsByClassName('input')[1].value === '') {
                message.error('請輸入電子郵件');
                return;
            }
            // @ts-ignore
            if (document.getElementsByClassName('input')[2].value === '') {
                message.error('請輸入公司名稱');
                return;
            }
            // @ts-ignore
            if (document.getElementsByClassName('input')[3].value === '') {
                message.error('請輸入職銜');
                return;
            }
            // @ts-ignore
            if (document.getElementsByClassName('input')[4].value === '') {
                message.error('請輸入聯絡電話');
                return;
            }
            // @ts-ignore
            if (document.getElementsByClassName('input')[5].value === '') {
                message.error('請輸入傳真');
                return;
            }
            // @ts-ignore
            if (document.getElementsByClassName('input')[6].value === '') {
                message.error('請輸入查詢事項');
                return;
            }
            // @ts-ignore
            const name = document.getElementsByClassName('input')[0].value;
            // @ts-ignore
            const email = document.getElementsByClassName('input')[1].value;
            // @ts-ignore
            const companyName = document.getElementsByClassName('input')[2].value;
            // @ts-ignore
            const jobTitle = document.getElementsByClassName('input')[3].value;
            // @ts-ignore
            const phone = document.getElementsByClassName('input')[4].value;
            // @ts-ignore
            const fax = document.getElementsByClassName('input')[5].value;
            // @ts-ignore
            const article = document.getElementsByClassName('input')[6].value;

            axios.post('https://systemapi.grandtg.com/sapi/pushContactusMessage', {
                name: name,
                email: email,
                companyName: companyName,
                jobTitle: jobTitle,
                phone: phone,
                fax: fax,
                article: article
            }).then((res) => {
                if(res.data.code === 200) {
                    message.success(res.data.message);
                    setTimeout(() => {
                        window.location.reload();
                    },500)
                } else {
                    message.error('发送失败，请联系管理员');
                }
            })
        } else {
            message.error(`${localStorage.language === 'hk' ? '請同意網站使用條款及私隱政策後送出' : (localStorage.language === 'en' ? 'Please agree to the website terms of use and privacy policy before sending' : '请同意网站使用条款及私隐政策后送出')}`);
        }
    }

    return(
        <div className="ContactUsContainer">
            <div className="navBottomBanner">
                <img className={"contactusBanner"} src={"/MissionVisionCorporateValues.png"} />
                <h1 className={"navBottomBannerFonts"}>{localStorage.language === 'hk' ? '聯絡我們' : (localStorage.language === 'en' ? 'CONTACT US' : '联络我们')}</h1>
            </div>

            <div className="ContactUsContent">
                <div className="contactContainerTitle">
                    <h2 style={{ borderBottom: '1px solid #000',  display: 'inline-table' }}>
                        {localStorage.language === 'hk' ? '大唐潼金控股有限公司' : (localStorage.language === 'en' ? 'Grand T G Gold Holdings Limited' : '大唐潼金控股有限公司')}
                    </h2>
                    <div className="contactContainerDesc">
                        <p>
                            {localStorage.language === 'hk' ? '上環皇后大道中305-311號永業中心8樓A-B室' : (localStorage.language === 'en' ? `Room A-B, 8th Floor, Centre Mark II, 305-311 Queen's Road Central, Sheung Wan, Hong Kong.` : '上环皇后大道中305-311号永业中心8楼A-B室')}
                        </p>
                        <p>
                            {localStorage.language === 'hk' ? '電話: (852) 2598 8566' : (localStorage.language === 'en' ? 'Tel: (852) 2598 8566' : '电话: (852) 2598 8566')}
                        </p>
                        <p>
                            {localStorage.language === 'hk' ? '傳真: (852) 2598 8560' : (localStorage.language === 'en' ? `Fax: (852) 2598 8560` : '传真: (852) 2598 8560')}
                        </p>
                        <p className={"mail"}>
                            <a className="mail" href="mailto:info@grandtggold.com">
                                {localStorage.language === 'hk' ? '電郵: info@grandtggold.com' : (localStorage.language === 'en' ? `Email: info@grandtggold.com` : `电邮: info@grandtggold.com`)}
                            </a>
                        </p>
                    </div>
                </div>

                <div className={"submitForm"}>
                    <Input className={"input"} placeholder={localStorage.language === 'hk' ? '聯絡人姓名' : (localStorage.language === 'en' ? 'Name' : '联络人姓名')} />
                    <Input className={"input"} placeholder={localStorage.language === 'hk' ? '電子郵件' : (localStorage.language === 'en' ? 'E-mail' : '电子邮件')} />
                    <Input className={"input"} placeholder={localStorage.language === 'hk' ? '公司名稱' : (localStorage.language === 'en' ? 'Company name' : '公司名称')} />
                    <Input className={"input"} placeholder={localStorage.language === 'hk' ? '職銜' : (localStorage.language === 'en' ? 'Job Title' : '职衔')} />
                    <Input className={"input"} placeholder={localStorage.language === 'hk' ? '聯絡電話' : (localStorage.language === 'en' ? 'Phone' : '联络电话')} />
                    <Input className={"input"} placeholder={localStorage.language === 'hk' ? '傳真' : (localStorage.language === 'en' ? 'Fax' : '传真')} />
                    <Input.TextArea className={"input"} rows={4} placeholder={localStorage.language === 'hk' ? '查詢事項' : (localStorage.language === 'en' ? 'Enquiries' : '查询事项')} />
                    <p style={{ marginTop: '20px', marginBottom: '20px' }}><Checkbox onChange={onChange}>{localStorage.language === 'hk' ? '我同意 網站使用條款及私隱政策' : (localStorage.language === 'en' ? 'I agree to the Terms of Service and Privacy Policy' : '我同意 网站使用条款及私隐政策')}</Checkbox></p>
                    <Button onClick={submitForm} type="primary">{localStorage.language === 'hk' ? '送出' : (localStorage.language === 'en' ? 'Submit' : '送出')}</Button>
                </div>
            </div>
        </div>
    );
}
export default ContactUs;
